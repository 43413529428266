.page-root {
  font-size: 18px;
  color: #000;
  padding-bottom: 40px;
}

.page-root input[type="radio"] {
  height: 22px;
  width: 22px;
  border: 1px solid #677384;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;
  margin-top: 0;
}

.page-root input[type="checkbox"] {
  height: 22px;
  width: 22px;
  border: 1px solid #677384;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;
  margin-top: 0;
}

.sv_complete_btn {
  margin-bottom: 20px;
}

@media print {
  html, body {
    height: initial;
    overflow: initial;
    -webkit-print-color-adjust: exact;
  }
}

@page {
  size: auto;
}

.question-title {
  display: flex;
  align-items: baseline;
  padding: 0px 16px;
  margin: 24px 0px 10px 0px;
  font-size: 18px;
  color: #000;
  font-weight: 700;
}

.question-instructional {
  padding: 16px 16px;
  font-weight: bold;
  font-size: 18px;
  color: #000;
  max-width: 70vw;
}

.page-title {
  font-size: 24px;
  color: #000;
  padding: 5px 16px;
  margin: 0px;
}

.page-title + div:not(.sv_row) {
  padding: 0px 16px;
}

.question-number {
  text-align: center;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
  height: 43px;
  width: 43px;
  padding-left: 4px;
  border: 2px solid #285f74;
  border-radius: 4px;
}

.checkbox-item {
  padding: 10px 10px 10px 20px;
}

.checkbox-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.checkbox-control-label > span {
  display: flex;
}

.radio-item {
  padding: 10px 10px 10px 20px;
}

.radio-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.radio-control-label>span {
  display: flex;
}

.navigation-button {
  background: #285f74;
  font-size: 18px;
  padding: 0 16px;
  color: white;
  margin-left: 16px;
  margin-top: 20px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  min-width: 64px;
  height: 36px;
  vertical-align: middle;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s;
}

.sv_q_matrix_dropdown {
  padding: 0px 16px;
  width: 100%;
}

.sv_q_matrix_dropdown tbody tr:nth-child(odd) {
  background: #F5F8FA;
}

.sv_q_matrix_dropdown td {
  padding: 0.75rem 0rem;
}

.sv_q_matrix_dropdown td:first-child {
  min-width: 10%;
}

.sv_q_matrix_dropdown>thead>tr>th:not(:first-child)  {
  width: 10%;
}

.sv_q_matrix_dropdown>thead>tr>th:not(:first-child) {
  padding: 0rem 0.5rem 0.5rem 0rem;
  vertical-align: top;
}

.sv_q_matrix_dropdown .sv_matrix_cell .checkbox-item {
  padding: 0px;
}

.sv_q_matrix_dropdown .sv_matrix_cell .checkbox-item .checkbox-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sv_select_wrapper {
  margin-left: 16px;
  margin-right: 5px;
}

.sv_q_dropdown_control {
  font-size: 18px;
  width: 40%;
  height: 3rem;
  border: 1px solid #677384;
  box-sizing: border-box;
  font-family: inherit;
  padding: 0em 1.5em 0em .87em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-prefix-chevron-down' id='icon-prefix-chevron-down' viewBox='0 0 24 24'%3E%3Cpolyline points='6 9 12 15 18 9' fill='none' stroke-linecap='round' stroke-width='4' stroke='dimgray' %3E%3C/polyline%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.2em 100%;
  color: #000;
}

.sv_q_dropdown_control:focus {
  border: 2px solid #677384;
  outline: none;
}

.sv_q_dropdown_control:hover {
  border: 2px solid #677384;
  outline: none;
}

@media only screen and (min-width: 800px) {
  .sv_q_matrix_dropdown>thead>tr>th {
     min-width: 7vw;
  }
}

@media only screen and (min-width: 1400px) {
  .sv_q_matrix_dropdown>thead>tr>th {
    min-width: 7vw;
    max-width: 7vw;
  }
}

.table {
  padding: 0px 16px;
  width: 100%;
}

.table tbody tr:nth-child(odd) {
  background: #F5F8FA;
}

.table td {
  padding: 0.75rem 0rem;
  vertical-align: top;
}

.table td:first-child {
  min-width: 10%;
}

.table td>label {
  text-align: center;
}

.table th {
  text-align: center;
}

.table>thead>tr>th:not(:first-child)  {
  width: 10%;
}

.table>thead>tr>th:not(:first-child) {
  padding: 0rem 0.5rem 0.5rem 0rem;
  vertical-align: top;
}

.sv_main span {
  word-break: normal !important;
  color: #000;
}

.sv_container .sv_body {
  padding: 0px;
}

.sv_container .sv_body > span:only-child {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 18px;
}

.input-field {
  width: 95%;
  min-height: 45px;
  padding: 0px 16px;
  margin: 0px 0px 0px 16px;
  font-size: 18px;
  border: 1px solid #677384;
  border-radius: 5px;
  font-family: Open Sans, sans-serif;
}

.input-field:hover {
  border: 1px solid #677384;
  outline: none;
}

.input-field:focus {
  border: 2px solid #677384;
  outline: none;
}

.sv-item__control-label {
  display: none;
}

.sv-root-modern .sv-radio--checked .sv-radio__svg  {
  border-color: #285f74;
  fill: #285f74;
}

.sv_q_rating {
  color: #000;
  padding-bottom: 3px;
  margin: 0 18px;
}

.sv-rating__item {
    position: relative;
    display: inline;
}

.sv-root-modern .sv-rating--disabled .sv_q_rating_item_text {
    color: rgb(219, 219, 219);
    border-color: rgb(219, 219, 219);
}

.sv-rating--disabled .sv_q_rating_item_text {
    cursor: default;
    color: #dbdbdb;
    border-color: #dbdbdb;
}

.sv-root-modern .sv_q_rating_item_text {
    color: rgb(159, 159, 159);
    border: 0.1875em solid rgb(159, 159, 159);
}

.sv_q_rating_item_text {
  height: 40px;
  width: 42px;
  color: #000;
  border: 1px solid #677384;
  text-align: center;
  display: inline-flex;
  vertical-align: middle;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.13;
  cursor: pointer;
  box-sizing: border-box;
  margin: 3px 0px;
}

.sv_q_rating_item_text span {
  margin: auto;
}

.sv_q_rating_item:first-child .sv_q_rating_item_text {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sv_q_rating_item:last-child {
  position: relative;
}

.sv_q_rating_item:last-child .sv_q_rating_item_text {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.active .sv_q_rating_item_text, .sv_q_rating_item:hover .sv_q_rating_item_text  {
  background-color: #285f74;
}

.active .sv_q_rating_item_text span, .sv_q_rating_item:hover .sv_q_rating_item_text span {
  color: #FFF;
}

.sv_q_rating_min_text {
  margin-right: 5px;
  font-size: 16px;
}

.sv_q_rating_max_text {
  margin-left: 5px;
  font-size: 16px;
}

.sv_qstn_error_top {
  color: #E6000D;
  margin: 0px 0px 8px 16px;
}

/* Fixed  an additional empty spacing, see: https://mentormate.atlassian.net/browse/JADF-728 */

.sv_main .sv-visuallyhidden {
  position: fixed !important;
  overflow: hidden !important;
}

.sv_column {
  position: relative;
}

.sv_logo__image {
  width: 100%;
  max-width: 600px;
}

@media screen and (max-width: 900px) {
  .sv_q_dropdown_control {
    width: 98%;
  }

  .sv_q_matrix {
    border: 0;
  }

  .question {
    font-weight:bold;
  }

  .sv_q_matrix caption {
    font-size: 1.3em;
  }

  .sv_q_matrix label {
    opacity: 0;
  }

  .sv_q_matrix thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .sv_q_matrix tr {
    border-bottom: 2px solid #000 !important;
    padding-bottom: 20px;
    flex-direction: column-reverse;
    display: flex;
  }

  .sv_q_matrix td:not(:first-child) {
    display: block;
    text-align: left;
    border: 1px solid #677384;
    margin: 5px 10px;
    border-radius: 5px;
    transition: background 200ms ease-in-out;
  }

  .sv_q_matrix td:hover {
    border: 2px solid #677384;
  }

  .sv_q_matrix td:has(> label.checked) {
    background: #00A0AF;
    border: 2px solid #677384;
  }

  .sv_q_matrix td::after {
    content: attr(headers);
    padding-left: 15px;
    color: #000;
  }

  .sv_q_matrix td:first-child {
    order: 1;
  }

  .sv_q_m_label input {
    float: left;
  }

}

@media only screen and (max-width: 750px) {

  .sv_q_rating_min_text {
    display: block;
  } 

  .sv_q_rating_item_text {
    width: 9%;
    max-width: 42px;
    min-width: 25px;
  }

  .sv_q_rating_max_text {
    float: right;
    position: absolute;
    top: -130%;
    right: 0%;
  }
}

@media only screen and (max-width: 480px) {
  .sv_logo__image {
    display: none;
  }

  .page-title {
    margin: 0px;
  }
}

@media only screen and (max-width: 330px) {
  .sv_q_rating_max_text {
    float: none;
    position: relative;
  }
}